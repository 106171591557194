import { gql } from "graphql-request";

export const APPROVE_CHAMPIONSHIPS_REQUESTS = gql`
mutation champs_update($id:Int,$fees:Int,$approved:Boolean){
    ChampsUpdate(champs:{id:$id,fees:$fees,approved:$approved}){
        name,
        fees,
        approved
    }
}
 `

 export const APPROVE_CHAMPIONSHIPS_REQUESTS_NO_REFs = gql`
  mutation champs_update($id:Int,$fees:Int,$approved:Boolean){
    ChampsUpdate(champs:{id:$id,fees:$fees,approved:$approved}){
        name,
        fees,
        approved
    }
}
 `

 export const DENY_CHAMPIONSHIPS_REQUESTS = gql`
 mutation deny_champ($id:Int){
    ChampsUpdate(champs:{id:$id,approved:false}){
      name
      approved
    }
  }
 `