<template>
  <div class="container mx-2">
    <!-- Breadcrumb  -->
    <va-card>
      <va-card-content>
        <va-breadcrumbs color="primary">
          <va-breadcrumbs-item
            :to="{ name: 'requests' }"
            :label="$t('requests')"
          />
          <va-breadcrumbs-item :label="championshipRequest.name" />
        </va-breadcrumbs>
      </va-card-content>
    </va-card>

    <form class="mt-4" @submit.prevent="approveRequest()">
      <va-card gradient color="#f0f0f0">
        <va-card-content class="p-5" style="margin-bottom: 25px">
          <div class="row justify--space-between" style="padding: 25px">
            <va-avatar
              style="width: 6rem; height: 6rem"
              :src="championshipRequest.logo"
            />
            <h1 class="text-center" style="font-size: 2rem; display: inline">
              {{ championshipRequest.name }}
            </h1>

            <va-chip shadow flat>{{
              new Date(championshipRequest.created).toLocaleString()
            }}</va-chip>
          </div>
          <h1 class="text-center" style="font-size: 1.5rem">
            {{ $t("by") }}: {{ championshipRequest.creator.name }}
            <hr />
          </h1>
          <div class="row md6 justify--space-between" style="padding: 25px">
            <p style="line-height: 2.2">
              <strong>{{ $t("teams_count") }} </strong> :
              {{ championshipRequest.teams_count }}
              <br />
              <strong>{{ $t("players_count") }} </strong> :
              {{ championshipRequest.team_players_count }}
              <br />
              <strong>{{ $t("championship_type") }}</strong> :
              {{
                $i18n.locale == "en"
                  ? championshipRequest.champ_type.name
                  : championshipRequest.champ_type.name_ar
              }}
            </p>
            <div v-if="requestStage == 1" style="line-height: 2.2">
              <strong> {{ $t("has_referees") }} </strong> :
              {{ $t(`${championshipRequest.has_referees}`) }}
              <div
                v-if="championshipRequest.has_referees == false"
                style="margin-top: 10px; max-width: 300px"
              >
                <label for="#referees">{{ $t("menu.referees") }}</label>
                <va-select
                  id="referees"
                  class="mb-4"
                  :options="referees"
                  v-model="selectedReferees"
                  :value-by="(referee) => referee.id"
                  :text-by="(referee) => referee.name"
                  :track-by="(referee) => referee.id"
                  :loading="loading"
                  @update:modelValue="check(selectedReferees)"
                  :rules="[
                    (selectedReferees) =>
                      (Array.isArray(selectedReferees) &&
                        selectedReferees.length >= 1) ||
                      isRequired,
                  ]"
                  clearable
                  :color="refColor"
                  multiple
                  searchable
                  required
                >
                  <template #append>
                    <va-icon
                      v-if="(refErrors = '')"
                      name="check"
                      color="#039303"
                    />
                  </template>
                </va-select>
                <p class="errors">{{ refErrors }}</p>
                <va-chip
                  v-for="chip in selectedReferees"
                  :key="chip"
                  :color="refColor"
                  size="small"
                  class="mr-2 my-2"
                  closeable
                  @update:modelValue="deleteChip(chip)"
                >
                  {{ chip.name }}
                </va-chip>
              </div>
            </div>
          </div>
          <va-input
            v-if="requestStage == 1"
            required
            class="gy-5 px-5"
            :keypress="validateFees(fees)"
            v-model="fees"
            :color="feeColor"
            :label="$t('subscription_fees')"
            mask="numeral"
          />
          <p style="margin-top: 5px !important" class="errors">
            {{ feesErrors }}
          </p>
        </va-card-content>
        <va-card-actions
          v-if="requestStage == 1"
          class="justify--space-between"
          align="right"
        >
          <va-button
            type="submit"
            color="#039303"
            gradient
            :disabled="validate"
            :loading="loading"
          >
            {{ $t("approve") }}
            <span class="material-icons px-1"> task_alt </span>
          </va-button>
          <va-button @click="denyRequest" color="#e42222">
            {{ $t("deny") }}
            <span class="material-icons px-1"> close </span>
          </va-button>
        </va-card-actions>
      </va-card>
    </form>
    <!-- TEAMS -->
    <va-card gradient color="#f0f0f0" v-if="requestStage == 2">
      <va-card-title class="row justify--space-between px-5">
        <strong style="font-size: 0.7rem">{{ $t("menu.teams") }}</strong>
        <strong class="text-large">{{
          `${teams.length}/${championshipRequest.teams_count}`
        }}</strong>
      </va-card-title>
      <va-card-content
        style="flex-wrap: wrap"
        class="teams-row justify--space-evenly"
      >
        <div class="row justify--space-around" v-if="teams.length > 0">
          <va-card
            gradient
            color="#2f4d9daa"
            v-for="team in teams"
            :key="team"
            style="line-height: 2; position: relative; max-width: max-content"
            class="py-4 px-4 my-2 mx-2"
          >
            <va-card-content class="team-container">
              <va-image
                style="border-radius: 10px"
                :src="$store.state.imgUrl + team.team.logo"
              />
              <strong class="px-2 py-2"> {{ team.team.name }}</strong>
            </va-card-content>
          </va-card>
        </div>

        <div
          v-else
          class="col align--center justify--space-evenly"
          style="height: 35vh"
        >
          <h1 style="font-size: 32px">
            {{ $t("no_teams") }}
            <span class="material-icons"> flag </span>
          </h1>
        </div>
      </va-card-content>
      <va-card-actions
        v-if="requestStage == 2 && teams.length > 0"
        class="justify--space-between"
        align="right"
      >
        <va-button
          @click="approveTeams"
          color="#039303"
          gradient
          :loading="loading"
          :disabled="teamsCompleted"
        >
          {{ $t("approve") }}
          <span class="material-icons px-1"> task_alt </span>
        </va-button>
      </va-card-actions>
    </va-card>
  </div>
</template>

<script>
import { GraphQLClient, request, gql } from "graphql-request";
import {
  APPROVE_CHAMPIONSHIPS_REQUESTS,
  DENY_CHAMPIONSHIPS_REQUESTS,
} from "@/graphql/mutations/championships/requests";
import { GET_REFEREES } from "@/graphql/queries/users";
import ChampionshipTeams from "@/pages/admin/championships/components/ChampionshipTeams.vue";
export default {
  components: { ChampionshipTeams },
  data() {
    return {
      requestStage: null,
      championshipRequest: {},
      loading: false,
      teams: [],
      referees: [],
      selectedReferees: [],
      refErrors: "",
      refColor: "",
      color: "#031093",
      fees: 3000,
      feesErrors: "",
    };
  },
  async created() {
    this.requestStage = +this.$route.query.stage;

    this.championshipRequest = JSON.parse(localStorage["championship"]);
    if (this.requestStage === 1) {
      const response = await request(this.$store.state.appUrl, GET_REFEREES);
      this.referees = response.referees;
      this.loading = false;
      return;
    }
    // Team Approval Stage
    if (this.requestStage === 2) {
      this.getChampTeams();
    }
  },
  methods: {
    check(selectedReferees) {
      if (this.championshipRequest.has_referees) return;
      if (Object.values(selectedReferees).length == 0) {
        this.refErrors = this.$t("errors.referees_count");
        this.refColor = "#e42222";
      } else {
        this.refErrors = "";
        this.refColor = "#039303";
      }
    },

    deleteChip(chip) {
      this.selectedReferees = this.selectedReferees.filter((v) => v !== chip);
      if (this.selectedReferees.length < 3) {
        this.refErrors = this.$t("errors.referees_count");
        this.color = "#e42222";
      } else {
        this.refErrors = "";
        this.color = "#039303";
      }
    },

    validateFees(fees) {
      if (fees <= 0) {
        this.feesErrors = this.$t("errors.fee_amount");
        this.feeColor = "#e42222";
      } else {
        this.feesErrors = "";
        this.feeColor = "#039303";
      }
    },

    async approveRequest() {
      this.loading = true;
      const ADD_REFEREE = gql`
        mutation ($champ_id: Int, $referee_id: Int) {
          ChampRefereesCreate(
            champ_referees: { champ_id: $champ_id, user_id: $referee_id }
          ) {
            id
          }
        }
      `;

      if (this.feesErrors != "" || this.selectedReferees.length == 0) {
        this.check(this.selectedReferees);
        if (this.refErrors != "") return;
      }
      const client = new GraphQLClient(this.$store.state.appUrl, {
        headers: {
          authorization: "Bearer MY_TOKEN",
        },
      });
      for (let i = 0; i < this.selectedReferees.length; i++) {
        await client.request(ADD_REFEREE, {
          champ_id: +this.championshipRequest.id,
          referee_id: this.selectedReferees[i].id,
        });
      }

      await client.request(APPROVE_CHAMPIONSHIPS_REQUESTS, {
        id: +this.championshipRequest.id,
        fees: +this.fees,
        approved: true,
      });
      this.loading = false;
      await this.$swal.fire({
        text: this.$t("approved_champ_text"),
        icon: "success",
        confirmButtonColor: "#039303",
        confirmButtonText: this.$t("ok"),
      });

      this.$router.push({ name: "requests" });
    },

    denyRequest() {
      this.$swal
        .fire({
          title: this.$t("sure?"),
          text: this.$t("deny_champ_text"),
          icon: "question",
          showCancelButton: true,
          cancelButtonColor: "#6e7881",
          confirmButtonColor: "#e42222",
          confirmButtonText: this.$t("deny_champ_button_text"),
          cancelButtonText: this.$t("cancel"),
        })
        .then((result) => {
          if (result.isConfirmed) {
            const client = new GraphQLClient(this.$store.state.appUrl, {
              headers: {
                authorization: "Bearer MY_TOKEN",
              },
            });

            const response = client.request(DENY_CHAMPIONSHIPS_REQUESTS, {
              id: this.championshipRequest.id,
            });
            this.$swal.fire({
              text: this.$t("denied_champ_text"),
              icon: "success",
              confirmButtonColor: "#039303",
              confirmButtonText: this.$t("ok"),
            });
            this.$router.push({ name: "requests" });
          }
        });
    },

    async getChampTeams() {
      const GET_CHAMP_TEAMS = gql`
        query ($id: Int) {
          ChampTeams(where: { champ_id: $id }) {
            team {
              id
              logo
              name
            }
          }
        }
      `;
      const response = await request(
        this.$store.state.appUrl,
        GET_CHAMP_TEAMS,
        { id: +this.$route.params.id }
      );
      this.teams = response.ChampTeams;
    },

    async approveTeams() {
      this.loading = true;
      const APPROVE_TEAMS_PAYMENTS = gql`
        mutation ($id: Int, $now: Date) {
          ChampsUpdate(champs: { id: $id, payment_time: $now }) {
            payment_time
          }
        }
      `;
      request(this.$store.state.appUrl, APPROVE_TEAMS_PAYMENTS, {
        id: +this.$route.params.id,
        now: new Date(),
      });

      // Create Matches
      const CREATE_MATCHES = gql`
        mutation (
          $champ_id: Int
          $champ_type_id: Int
          $teams: [newChampTeamsFromUser]
        ) {
          newChampionshipFromUser(
            ChampionshipInput: {
              champ_id: $champ_id
              champ_type_id: $champ_type_id
              teams: $teams
            }
          ) {
            status
            msg
          }
        }
      `;
      const response = await request(this.$store.state.appUrl, CREATE_MATCHES, {
        champ_id: +this.$route.params.id,
        champ_type_id: +this.championshipRequest?.champ_type?.id,
        teams: this.teams.map((team) => {
          return { id: team?.team?.id };
        }),
      });
      if (response.newChampionshipFromUser.status) {
        await this.$swal.fire({
          text: this.$t("approved_champ_text"),
          icon: "success",
          confirmButtonColor: "#039303",
          confirmButtonText: this.$t("ok"),
        });
        this.$router.push({ name: "requests" });
      }
      this.loading = false;
    },
  },
  computed: {
    validate() {
      if (
        this.fees <= 0 ||
        (this.championshipRequest.has_referees == false &&
          this.selectedReferees.length < 1)
      )
        return true;
    },
    teamsCompleted() {
      if (this.teams.length < this.championshipRequest.teams_count) return true;
    },
  },
};
</script>

<style scoped>
.row > * {
  width: unset;
}
.errors {
  margin-top: -25px;
  padding-left: 10px;
  color: #e42222;
}
/* .team-container {
  display: flex;
  flex-direction: column;
  padding: 0.8rem 1.2rem;
  border-radius: 5px;
  align-items: center;
} */
</style>
